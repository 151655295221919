import authenticate from '@/router/middleware/authenticate';
import redirectIfAuthenticated from '@/router/middleware/redirectIfAuthenticated';
import findUser from '@/router/middleware/findUser';

export default {
  /**
   * The application's global HTTP middleware stack.
   * These middleware are run during every request to your application.
   */
  global: {
    before: [],
    after: [],
  },

  /**
   * The application's route middleware groups.
   */
  group: {
    auth: [
      authenticate,
      findUser,
    ],
  },

  /**
   * The application's route middleware.
   * These middleware may be assigned to groups or used individually.
   */
  route: {
    guest: redirectIfAuthenticated,
  },
};
