/* ============
 * VeeValidate
 * ============
 *
 * VeeValidate is a validation library for Vue.js.
 * It has plenty of validation rules out of the box and support for custom ones as well.
 * It is template based so it is similar and familiar with the HTML5 validation API.
 *
 * https://baianat.github.io/vee-validate
 */

import Vue from 'vue';
import { ValidationProvider, ValidationObserver, configure, extend, localize } from 'vee-validate';

import en from 'vee-validate/dist/locale/en.json';

// rules
import {
  required,
  max,
  email,
  confirmed,
  alpha_dash as alphaDash,
  numeric,
  min_value as minValue,
  max_value as maxValue,
  regex,
  excluded,
  ext,
} from 'vee-validate/dist/rules';

import alias from './rules/alias';

configure({});

// turn on rules
extend('required', required);
extend('max', max);
extend('email', email);
extend('confirmed', confirmed);
extend('alpha_dash', alphaDash);
extend('numeric', numeric);
extend('min_value', minValue);
extend('max_value', maxValue);
extend('regex', regex);
extend('excluded', excluded);
extend('ext', ext);

// turn on custom rules
extend('alias', alias);

localize('en', en);

// define validation components globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
