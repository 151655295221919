export default {
  common: {
    logout: 'Logout',
    successURLTitle: 'Return to customer portal',
    cancelURLTitle: 'Cancel and return to customer portal',
    cancel: 'Cancel',
    add: 'Add',
    update: 'Update',
    success: 'Success',
    error: 'Error',
    delete: 'Delete',
    close: 'Close',
    download: 'Download product',
  },

  validations: {
    /**
     * Placeholders:
     * {_field_} which is the field name.
     * {_value_} which is the field value.
     * {_rule_} which is the rule name.
     *
     * @see https://baianat.github.io/vee-validate/guide/localization.html#messages-format
     */
    alias: 'The {_field_} field may only contain alpha-numeric characters, @, dot, dash or underscore',
  },

  // Components
  TheLayoutAuth: {
    welcome: 'Welcome to Labs64 NetLicensing Customer Portal',
  },

  TheLayoutDefault: {
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    imprint: 'Imprint',
  },

  LicensesByProduct: {
    name: 'License',
    status: 'Status',
    dateCreated: 'Date Created',
    active: 'active',
    inactive: 'inactive',
    expirationTimestamp: 'Expiration Timestamp',
    expires: 'Expires',
    expired: 'expired',
    startDate: 'Start Date',
    actions: 'Action',

    LMSubscription: {
      success: 'Valid until {date}, {to} left',
      danger: 'Not valid',
      buy: 'Buy',
    },

    LMQuota: {
      success: 'Available quota: {quota}',
      danger: 'Not valid',
      buy: 'Buy',
      quantity: 'Quota',
    },

    LMPayPerUse: {
      success: 'Remaining amount: {remaining}',
      warning: 'Remaining amount: {remaining}; less than 20% of the limit',
      danger: 'Not valid',
      buy: 'Buy',
      quantity: 'Quantity',
      remainingQuantity: 'Remaining Quantity',
    },

    LMTryAndBuy: {
      success: 'Enabled',
      warning: 'Trial mode, {to} left',
      danger: 'Not valid',
      buy: 'Buy',
    },

    LMFloating: {
      success: 'Available sessions: {remaining}',
      warning: 'Available sessions: {remaining}',
      danger: 'Not valid',
      buy: 'Buy',
      session: 'Session ID',
      maxSessions: 'Sessions',
      remainingSessions: 'Available Sessions',
      noSessions: 'No free sessions available',
      sessionCheckIn: 'Session is removed',
      deleteSession: 'Delete node secret',
      deleteSessionDescription: `This will permanently delete node secret.
            <p>Please type <strong>{key}</strong> to confirm.</p>`,
    },

    LMNodeLocked: {
      success: 'Available nodes: {remaining}',
      warning: 'Available nodes: {remaining}',
      danger: 'Not valid',
      buy: 'Buy',
      node: 'Node',
      nodesQuantity: 'Nodes',
      nodesRemaining: 'Available Nodes',
      noNodes: 'No free nodes available',
      nodeRemoved: 'Node secret is removed',
      deleteNode: 'Delete node secret',
      deleteNodeDescription: `This will permanently delete node secret.
            <p>Please type <strong>{key}</strong> to confirm.</p>`,
    },

    LMMultiFeature: {
      success: 'All {total} features are available',
      warning: '{available} of {total} features are available',
      danger: 'Not valid',
      buy: 'Buy',
    },

    LMPricingTable: {
      successForFeatureType: 'Current plan: {plan}',
      successForTimeVolumeType: 'Current plan: {plan} - valid until {expires}, {to} left',
      warning: 'Current plan: {plan} - valid until {date}, {to} left',
      danger: 'Not valid',
      buy: 'Buy',
      infinity: 'unlimited',
      value: 'Status',
    },

    LMRental: {
      success: 'Next rent valid until {expires}',
      warning: 'Next rent valid until {expires}',
      danger: 'Not valid',
      expires: 'Valid until',
      level: 'Level',
      levelGreen: 'Green',
      levelYellow: 'Yellow',
      levelRed: 'Red',
      buy: 'Buy',
    },
  },

  TheHeader: {
    email: 'Email',
    website: 'Website',
    aliases: 'Manage Aliases',
  },

  TextareaWithFileUpload: {
    attachFiles: 'Attach files by dragging & dropping, selecting or pasting them.',
    uploadFiles: 'Uploading file ({progress}%)...',
    unsupportedFileTypes: 'File type isn\'t supported.',
    unsupportedFileTypesDescription: 'Please try again with the {types}.',
  },

  // Pages
  DoTokenActionsPage: {
    actionUnknown: 'Unknown token action!',
  },

  AliasesPage: {
    title: 'Aliases',
    name: 'Name',
    alias: 'Alias',
    addAlias: 'Add',
    addAliasDescription: 'New alias (enter to add)',
    addAliases: 'Add Aliases',
    addAliasesDescription: 'Enter new aliases separated by comma, semicolon or newline',
    addAliasesFromFile: 'Add aliases from file',
    aliases: '{count} alias | {count} aliases',
    aliasesWillBeIgnored: 'The following aliases are invalid and will be ignored:',
    listView: 'Change to list view',
    compactView: 'Change to compact view',
    search: 'Search aliases',
    noAliases: 'No aliases',
    aliasesUpdated: 'Aliases updated',
    aliasHasInvalidChars: 'The alias may only contain alpha-numeric characters, @, dot, dash or underscore',
    aliasHasInvalidLength: 'Alias length may not be greater than {max} characters',
  },

  Error403Page: {
    title: 'Access Forbidden',
    description: `We're sorry, but you do not have access to this page or resource.
            Please contact us at
            <a href="mailto:info@netlicensing.io">info@netlicensing.io</a>`,
  },

  Error404Page: {
    title: 'Page Not Found',
    description: `We're sorry, the page you requested could not be found.
            Please contact us at
            <a href="mailto:info@netlicensing.io">info@netlicensing.io</a>`,
  },

  Error500Page: {
    title: 'Oops! Something went wrong.',
    description: `The server encountered something unexpected that didn't allow it to complete the request.
            We apologize.`,
    sendError: `Please send this information as text (not screenshot) to
            <a href="mailto:info@netlicensing.io?body={error}">info@netlicensing.io</a>`,
  },
};
