/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_COUNTRIES = 'SET_COUNTRIES';

export default {
  SET_PRODUCTS,
  SET_COUNTRIES,
};
