export default async function (to, from, next) {
  const { $store } = this;

  // check if user authenticated
  if (!$store.getters['auth/authenticated']) {
    return next({ name: 'Error403Page' });
  }

  return next();
}
