// NetLicensing Client
import { Service } from 'netlicensing-client';

export const updateAliases = async (context, licenseeNumber, aliases) => {
  const aliasesStr = (Array.isArray(aliases)) ? JSON.stringify(aliases) : aliases;
  return Service.request(context, 'post', `customerportal/updatealiases/${licenseeNumber}`, { aliases: aliasesStr });
};

export default {
  updateAliases,
};
