/* ============
 * Getters for the countries module
 * ============
 *
 * The getters that are available on the
 * countries module.
 */

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _isEmpty from 'lodash/isEmpty';

export default {
  isProductsLoaded(state) {
    return !_isEmpty(state.products);
  },

  isCountriesLoaded(state) {
    return !_isEmpty(state.countries);
  },
};
