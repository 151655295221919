/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export const defaults = () => ({
  licensee: null,

  validation: {
    ttl: '',
    items: [],
  },
});

export default defaults();
