/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

import atob from 'atob';

let token = {};

try {
  token = JSON.parse(atob(localStorage.getItem('AUTH_TOKEN')));
} catch (e) {
  token = {};
}

export default { token };
