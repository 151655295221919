import { Service, CastsUtils as castUtils } from 'netlicensing-client';

export default (service) => ({
  list(context, filter = { items: 100 }, ...args) {
    let all = [];

    const fetch = (page = 0) => service.list(...[context, { ...filter, page }, ...args])
        .then((entities) => {
          // save api
          all = all.concat(entities);

          // check if we has next page
          const { data: { items } } = Service.getLastHttpRequestInfo();

          if (castUtils('boolean', items.hasnext)) {
            // fetch next page
            return fetch(castUtils('int', items.pagenumber) + 1);
          }
          return entities;
        });

    return fetch().then(() => all);
  },
});
