/* ============
 * Mutations for the countries module
 * ============
 *
 * The mutations that are available on the
 * countries module.
 */
import { SET_PRODUCTS, SET_COUNTRIES } from './mutation-types';

export default {
  [SET_PRODUCTS](state, products) {
    state.products = products;
  },

  [SET_COUNTRIES](state, countries) {
    state.countries = countries;
  },
};
