import Vue from 'vue';

import aliases from './aliases';
import nodeSecrets from './nodeSecrets';
import sessions from './sessions';

const $api = {
  aliases,
  nodeSecrets,
  sessions,
};

Vue.use({
  install(V) {
    V.prototype.$api = $api;
  },
});

export default $api;
