/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */
/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _each from 'lodash/each';

import { defaults } from './state';

export default {
  find(state, licensee) {
    state.licensee = licensee;
  },

  validate(state, validation) {
    state.validation = { ...state.validation, ...validation };
  },

  reset(state) {
    _each(defaults(), (v, k) => {
      state[k] = v;
    });
  },
};
