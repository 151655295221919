/* ============
 * Actions for the user module
 * ============
 *
 * The actions that are available on the
 * user module.
 */

// NetLicensing js library
import { Service, LicenseeService, Constants as ClientConstants } from 'netlicensing-client';

// utils
import itemToObject from '@/utils/itemToObject';

export const find = async ({ commit, rootState, rootGetters }) => {
  if (!rootGetters['auth/authenticated']) {
    throw new Error('Unable to find account, user is not logged in');
  }

  const { auth: { token: { licenseeNumber } } } = rootState;

  const licensee = await LicenseeService.get(rootGetters['auth/context'], licenseeNumber);

  commit('find', licensee);
};

export const validate = async ({ commit, getters, rootState, rootGetters }) => {
  if (!rootGetters['auth/authenticated']) {
    throw new Error('Unable to make validation, user is not logged in');
  }

  if (getters.isValidationExpired) {
    const { auth: { token: { licenseeNumber } } } = rootState;

    const queryParams = { dryRun: true };
    const endpoint = `${ClientConstants.Licensee.ENDPOINT_PATH}/${licenseeNumber}/${ClientConstants.Licensee.ENDPOINT_PATH_VALIDATE}`;

    const { data: { items: { item: items }, ttl } } = await Service.post(rootGetters['auth/context'], endpoint, queryParams);

    const validation = { ttl, items: [] };

    items.forEach((v) => {
      validation.items.push(itemToObject(v));
    });

    commit('validate', validation);
  }
};

/**
 * Reset all user data to defaults
 *
 * @param commit
 */
export const reset = ({ commit }) => {
  commit('reset');
};

export default {
  find,
  validate,
  reset,
};
