/* ============
 * Routes File
 * ============
 *
 * The routes and redirects are defined in this file.
 */
export default [
  // Do
  {
    path: '/do/:token/:action',
    name: 'DoTokenActionsPage',
    component: () => import('@/views/pages/DoTokenActionsPage'),
    props: true,
  },

  // Home
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/pages/HomePage'),
    meta: { middleware: ['auth'] },
  },

  // Aliases
  {
    path: '/aliases',
    name: 'AliasesPage',
    component: () => import('@/views/pages/AliasesPage'),
    meta: { middleware: ['auth'] },
  },

  // 404 page
  {
    path: '*',
    name: 'Error404Page',
    component: () => import('@/views/pages/Error404Page'),
  },

  // 403 page
  {
    path: '*',
    name: 'Error403Page',
    component: () => import('@/views/pages/Error403Page'),
  },

  // 500 page
  {
    path: '*',
    name: 'Error500Page',
    component: () => import('@/views/pages/Error500Page'),
    props: true,
  },
];
