/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */

// NetLicensing-Client
import { Context, Constants } from 'netlicensing-client';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _get from 'lodash/get';

export default {
  authenticated(state) {
    return !!_get(state.token, 'active');
  },

  context(state) {
    return new Context({
      baseUrl: process.env.VUE_APP_CORE_BASE_URL,
      securityMode: Constants.APIKEY_IDENTIFICATION,
      apiKey: state.token.number,
    });
  },

  anonymousContext() {
    return new Context({
      baseUrl: process.env.VUE_APP_CORE_BASE_URL,
      securityMode: Constants.ANONYMOUS_IDENTIFICATION,
    });
  },
};
