/* ============
 * Actions for the countries module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

// NetLicensing js library
import { UtilityService, ProductService } from 'netlicensing-client';

// utils
import fetchAll from '@/utils/fetchAll';

import { SET_COUNTRIES, SET_PRODUCTS } from './mutation-types';

export const fetchCountries = async ({ commit, rootGetters }) => {
  commit(SET_COUNTRIES, await UtilityService.listCountries(rootGetters['auth/context']));
};

export const fetchProducts = async ({ commit, rootGetters }) => {
  commit(SET_PRODUCTS, await fetchAll(ProductService).list(rootGetters['auth/context']));
};

export default {
  fetchCountries,
  fetchProducts,
};
