/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import dayjs from 'dayjs';

// NetLicensing js library
import { TokenService, Service, itemToToken } from 'netlicensing-client';

export const login = async ({ commit, getters }, token) => {
  const actionToken = await TokenService.get(getters.anonymousContext, token);

  const { action, expirationTime } = actionToken;

  // check expiration
  if (dayjs(expirationTime).isBefore(dayjs())) {
    throw new Error('Token expired');
  }

  if (!action || action !== 'licenseeLogin') {
    throw new Error('Token action does not match action');
  }

  const { data: { items: { item: [item] } } } = await Service.post(
    getters.anonymousContext,
    `/token/${actionToken.number}/exec/${action}`,
  );

  // login mutation
  commit('login', itemToToken(item));
};

export const logout = async ({ commit }) => {
  commit('logout');
};

export default {
  login,
  logout,
};
