/* ============
 * State of the countries module
 * ============
 *
 * The initial state of the countries module.
 */

export default {
  products: [],
  countries: [],
};
