import Vue from 'vue';
import swal from 'sweetalert2';
import '@/assets/scss/plugins/sweetAlert2.scss';

Vue.use({
  install(V) {
    V.prototype.$swal = swal.mixin({
      // confirmButtonColor: '#d34416',
      // cancelButtonColor: '#d2d2d2',
    });
  },
});
