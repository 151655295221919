/* ============
 * Main File
 * ============
 *
 * Will initialize the application.
 */
import Vue from 'vue';

/* ============
 * Third party
 * ============
 *
 * Various third-party libraries to initialize the application.
 */
import { Service } from 'netlicensing-client';
import apiAxios from '@/axios/api';

/* ============
 * Plugins
 * ============
 *
 * Import the plugins.
 */
import VueToastr from 'vue-toastr';

import store from './store';
import router from './router';
import i18n from './i18n';

// use plugins
import './plugins/meta';
import './plugins/nprogress';
import './plugins/veeValidation';
import './plugins/sweetAlert2';
import './plugins/dayjs';
import './errors';
import './api';

/* ============
 * Styling
 * ============
 *
 * Import the application styling.
 */
import './assets/scss/main.scss';

/* ============
 * Main App
 * ============
 *
 * Last but not least, we import the main application.
 */
import App from './views/App';

// config
Vue.config.productionTip = process.env.NODE_ENV !== 'production';

Vue.use(VueToastr, {
  defaultPosition: 'toast-bottom-right',
});

Service.setAxiosInstance(apiAxios);

// eslint-disable-next-line no-new
new Vue({
  /**
   * Bind the Vue instance to the HTML.
   */
  el: '#app',

  /**
   * The localization plugin.
   */
  i18n,

  /**
   * The router.
   */
  router,

  /**
   * The Vuex store.
   */
  store,

  /**
   * The Vue meta.
   */
  meta: {
    titleTemplate: '%s | NetLicensing Customer Portal',
  },

  /**
   * Will render the application.
   *
   * @param {Function} h Will create an element.
   */
  render: (h) => h(App),
});
