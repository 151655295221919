/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */

import dayjs from '@/plugins/dayjs';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _isEmpty from 'lodash/isEmpty';

export default {
  loaded(state) {
    return !_isEmpty(state.licensee);
  },

  isValidationExpired(state) {
    const { validation: { ttl } } = state;
    return !ttl || dayjs(ttl).isBefore(dayjs());
  },
};
