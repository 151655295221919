// vuex & vue-router
import store from '@/store';
import router from '@/router';

// axios instance
import baseAxios from './base';

const apiAxios = baseAxios.create();

apiAxios.interceptors.response.use(
  (response) => response,
  async (e) => {
    const { response: { status } = {} } = e;

    if (status === 401) {
      await store.dispatch('auth/logout');
      await store.dispatch('user/reset');

      if (router.currentRoute.name !== '403Page') {
        window.location.reload();
      }
    }

    throw e;
  },
);

export default apiAxios;
