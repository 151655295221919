/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */
import btoa from 'btoa';

export default {
  login(state, token) {
    state.token = token;

    // save token number in local storage
    localStorage.setItem('AUTH_TOKEN', btoa(JSON.stringify(token)));
  },

  logout(state) {
    localStorage.removeItem('AUTH_TOKEN');
    state.token = null;
  },
};
